import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/light',
  },
  {
    path: '/login',
    name: '/login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue'),
    meta: {
      title: '登录',
      pageVal:'login'
    },
  },
  {
    path: '/enroll',
    name: '/enroll',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Enroll.vue'),
    meta: {
      title: '注册账号',
      pageVal:'enroll'
    },
  },
  {
    path: '/layout',
    redirect: '/light',
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/Layout.vue'),
    meta: {
      title: '首页',
      pageVal:'layout'
    },
    children:[
      {
        path: '/power',
        name: 'power',
        component: () => import( /* webpackChunkName: "about" */ '../views/power/Power.vue'),
        redirect: '/power/powerM',
        meta: {
          title: '电源',
          pageVal: 'power'
        },
        children: [{
          path: '/power/powerM',
          name: 'powerM',
          meta: {
            title: '电源管理',
            pageVal: 'power'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerManage.vue')
        }, {
          path: '/power/batchM',
          name: 'powerB',
          meta: {
            title: '电源批量管理',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/BatchManage.vue')
        }, {
          path: '/power/alarmStatis',
          name: 'alarmStatis',
          meta: {
            title: '电源报警统计',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/AlarmStatistics.vue')
        }, {
          path: '/power/powerUseRank',
          name: 'powerUseRank',
          meta: {
            title: '用电排行',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerUseRank.vue')
        }, {
          path: '/power/powerUseReport',
          name: 'powerUseReport',
          meta: {
            title: '用电报告',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerUseReport.vue')
        }, {
          path: '/power/powerData',
          name: 'powerData',
          meta: {
            title: '电量数据',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerData.vue')
        }, {
          path: '/power/efficiencyAnalysis',
          name: 'efficiencyAnalysis',
          meta: {
            title: '能效分析',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/EfficiencyAnalysis.vue')
        }, {
          path: '/power/powerDept',
          name: 'powerDept',
          meta: {
            title: '电源管理（部门）',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerManage.vue')
        }, {
          path: '/power/powerRoom',
          name: 'powerRoom',
          meta: {
            title: '电源管理（房间）',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/PowerManageRoom.vue')
        }, {
          path: '/power/earlyWarning',
          name: 'earlyWarning',
          meta: {
            title: '电源预警报警',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/EarlyWarning.vue')
        }, {
          path: '/power/repairManagement',
          name: 'repairManagement',
          meta: {
            title: '电源报修管理',
            pageVal: 'power1'
          },
          component: () => import( /* webpackChunkName: "about" */ '../views/power/RepairManagement.vue')
        }, ]
      },      
      {
        path: '/light',
        name: 'light',
        component: () => import(/* webpackChunkName: "about" */ '../views/light/Light.vue'),
        redirect: '/light/lightM',
        meta: {
          title: '灯光',
          pageVal:'light'
        },
        children: [{
          path: '/light/lightM',
          name: 'lightM',
          meta: {
            title: '灯光管理',
            pageVal:'light'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/light/LightManage.vue')
        },{
          path: '/light/batchM',
          name: 'lightB',
          meta: {
            title: '灯光批量管理',
            pageVal:'light'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/light/BatchManage.vue')
        },{
          path: '/light/lightQ',
          name: 'lightQ',
          meta: {
            title: '一键查询',
            pageVal:'light'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/light/LightQuery.vue')
        },{
          path: '/light/lightDept',
          name: 'lightM',
          meta: {
            title: '灯光管理（部门）',
            pageVal:'light1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/light/LightManageDept.vue')
        },{
          path: '/light/lightRoom',
          name: 'lightM',
          meta: {
            title: '灯光管理（房间）',
            pageVal:'light1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/light/LightManageRoom.vue')
        }]
      },
      {
        path: '/disinfect',
        name: 'disinfect',
        component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/Disinfect.vue'),
        redirect: '/disinfect/disinfectM',
        meta: {
          title: '消毒',
          pageVal:'disinfect'
        },
        children: [{
          path: '/disinfect/disinfectM',
          name: 'disinfectM',
          meta: {
            title: '消毒管理',
            pageVal:'disinfect'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/DisinfectManage.vue')
        },{
          path: '/disinfect/batchM',
          name: 'disinfectB',
          meta: {
            title: '消毒批量管理',
            pageVal:'disinfect'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/BatchManage.vue')
        },{
          path: '/disinfect/disinfectQ',
          name: 'disinfectQ',
          meta: {
            title: '一键查询',
            pageVal:'disinfect'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/DisinfectQuery.vue')
        },{
          path: '/disinfect/disinfectDept',
          name: 'disinfectM',
          meta: {
            title: '消毒管理（部门）',
            pageVal:'disinfect1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/DisinfectManageDept.vue')
        },{
          path: '/disinfect/disinfectRoom',
          name: 'disinfectM',
          meta: {
            title: '消毒管理（房间）',
            pageVal:'disinfect1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/disinfect/DisinfectManageRoom.vue')
        },]
      },
      {
        path: '/ac',
        name: 'ac',
        component: () => import(/* webpackChunkName: "about" */ '../views/ac/AC.vue'),
        redirect: '/ac/acM',
        meta: {
          title: '空调',
          pageVal:'ac'
        },
        children: [{
          path: '/ac/acM',
          name: 'acM',
          meta: {
            title: '空调管理',
            pageVal:'ac'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/ac/ACManage.vue')
        },{
          path: '/ac/batchM',
          name: 'acB',
          meta: {
            title: '空调批量管理',
            pageVal:'ac'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/ac/BatchManage.vue')
        },{
          path: '/ac/acQ',
          name: 'acQ',
          meta: {
            title: '一键查询',
            pageVal:'ac'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/ac/ACQuery.vue')
        },{
          path: '/ac/acDept',
          name: 'acM',
          meta: {
            title: '空调管理（部门）',
            pageVal:'ac1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/ac/ACManageDept.vue')
        },{
          path: '/ac/acRoom',
          name: 'acM',
          meta: {
            title: '空调管理（房间）',
            pageVal:'ac1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/ac/ACManageRoom.vue')
        }]
      },
      {
        path: '/curtain',
        name: 'curtain',
        component: () => import(/* webpackChunkName: "about" */ '../views/curtain/Curtain.vue'),
        redirect: '/curtain/curtainM',
        meta: {
          title: '窗帘',
          pageVal:'curtain'
        },
        children: [{
          path: '/curtain/curtainM',
          name: 'curtainM',
          meta: {
            title: '窗帘管理',
            pageVal:'curtain'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/curtain/CurtainManage.vue')
        },{
          path: '/curtain/batchM',
          name: 'curtainB',
          meta: {
            title: '窗帘批量管理',
            pageVal:'curtain'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/curtain/BatchManage.vue')
        },{
          path: '/curtain/curtainQ',
          name: 'curtainQ',
          meta: {
            title: '一键查询',
            pageVal:'curtain'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/curtain/CurtainQuery.vue')
        },{
          path: '/curtain/curtainDept',
          name: 'curtainM',
          meta: {
            title: '窗帘管理（部门）',
            pageVal:'curtain1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/curtain/CurtainManageDept.vue')
        },{
          path: '/curtain/curtainRoom',
          name: 'curtainM',
          meta: {
            title: '窗帘管理（房间）',
            pageVal:'curtain1'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/curtain/CurtainManageRoom.vue')
        }]
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "about" */ '../views/setting/Setting.vue'),
        redirect: '/setting/device',
        meta: {
          title: '设备',
          pageVal:'device'
        },
        children: [{
          path: '/setting/device',
          name: 'device',
          meta: {
            title: '中控管理',
            pageVal:'device'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/DeviceList.vue')
        },{
          path: '/setting/region',
          name: 'region',
          meta: {
            title: '单元管理',
            pageVal:'region'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/RegionList.vue')
        },{
          path: '/setting/timing',
          name: 'timing',
          meta: {
            title: '定时管理',
            pageVal:'timing'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/TimingList.vue')
        },{
          path: '/setting/user',
          name: 'user',
          meta: {
            title: '账号管理',
            pageVal:'user'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/UserList.vue')
        },{
          path: '/setting/company',
          name: 'company',
          meta: {
            title: '单位管理',
            pageVal:'company'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/CompanyList.vue')
        },{
          path: '/setting/password',
          name: 'password',
          meta: {
            title: '修改密码',
            pageVal:'password'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/Password.vue')
        },{
          path: '/setting/screen',
          name: 'screen',
          meta: {
            title: '数据大屏',
            pageVal:'screen'
          },
          component: () => import(/* webpackChunkName: "about" */ '../views/setting/Screen.vue')
        }]
      }
    ]
  },
 
]

const router = new VueRouter({
  routes
})

export default router
