import Vue from 'vue'
import './assets/iconfont/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

import * as echarts from 'echarts';

import axios from 'axios';
import qs from 'qs'
import moment from 'moment';

Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs

moment.locale('zh-cn');
Vue.filter('dateformat', (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(dataStr).format(pattern)
})

import VueSSE from 'vue-sse';
Vue.use(VueSSE);

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
router.beforeEach((to, from, next) => { 
 if (to.meta.title) {
   document.title = to.meta.title
 } else {
   document.title = ''
 }
 if(to.path  === '/login'||to.path  === '/enroll') {
  next()
 } else {
  var tokenStr = localStorage.getItem('token');
  if(!tokenStr){
    tokenStr = window.sessionStorage.getItem("token");
  }
  if(tokenStr) {
    next()
   } else {
    next('/login')
   }
 }
 
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
