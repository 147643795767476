<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
 name: 'App',
 created() {
      if (this.$router.path !== "/"){
        // this.$router.replace("/")
      }
    }
}
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>
