import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    regionName:"",
    regionNo:0,

    lightNoAll:0,    
    lightNo:0,
    lightIsOpen:false,

    curtainNoAll:0,    
    curtainNo:0,
    curtainIsOpen:false,

    acNoAll:0,    
    acNo:0,
    acStatus:1,
    acIsOpen:false,

    disinfectNoAll:0,
    disinfectNo:0,
    disinfectStatus:1,
    disinfectIsOpen:false,

    powerNoAll:0,
    powerNo:0,
    powerStatus:1,
    powerIsOpen:false,

    leftMenu:'',
    leftActiveMenu:'',//左侧选中菜单    
    lightRoomList:[],
    curtainRoomList:[],
    disinfectRoomList:[],
    powerRoomList:[],
    acRoomList:[],
  },
  mutations: {   
    changeActiveMenu(state,param) {
      state.leftActiveMenu = param;
      state.regionName=param.name;
      state.regionNo=param.children?.length;

      state.lightNo=param.lightNo;
      state.disinfectNo=param.disinfectNo;
      state.powerNo=param.powerNo;
      state.acNo=param.acNo;
      state.curtainNo=param.curtainNo;

      if(!state.leftActiveMenu.children){       
        state.lightIsOpen=false;
        var client_id=state.leftActiveMenu.clientId; 

        state.lightRoomList?.forEach(e=>{
          e.children?.forEach(c=>{            
            if(c.clientId==client_id){  
              state.lightIsOpen=c.isOpen;   
            }
          });
        });    

        state.curtainRoomList?.forEach(e=>{
          e.children?.forEach(c=>{            
            if(c.clientId==client_id){  
              state.curtainIsOpen=c.isOpen;   
            }
          });
        });  
          
        state.disinfectIsOpen=false;         
        state.disinfectRoomList?.forEach(e=>{
          e.children?.forEach(c=>{            
            if(c.clientId==client_id){  
              state.disinfectIsOpen=c.isOpen;   
            }
          });
        }); 
        
        state.powerIsOpen=false;         
        state.powerRoomList?.forEach(e=>{
          e.children?.forEach(c=>{            
            if(c.clientId==client_id){  
              state.powerIsOpen=c.isOpen;   
            }
          });
        }); 

        state.acIsOpen=false;         
        state.acRoomList?.forEach(e=>{
          e.children?.forEach(c=>{            
            if(c.clientId==client_id){  
              state.acIsOpen=c.isOpen;   
            }
          });
        }); 
        
        //console.log( state.disinfectIsOpen);      
      }else{       
          //console.log(state.leftActiveMenu);

          state.lightIsOpen=false;

          state.curtainIsOpen=false;

          state.disinfectIsOpen=false;  

          state.powerIsOpen=false;

          state.acIsOpen=false;

          state.leftActiveMenu.children?.forEach(e=>{            
            var client_id=e.clientId;  

            state.lightRoomList?.forEach(e=>{
              e.children?.forEach(c=>{            
                if(c.clientId==client_id && c.isOpen){  
                  state.lightIsOpen=c.isOpen;                    
                }
              });
            });    
            
            state.curtainRoomList?.forEach(e=>{
              e.children?.forEach(c=>{            
                if(c.clientId==client_id && c.isOpen){  
                  state.curtainIsOpen=c.isOpen;                    
                }
              });
            });   

            state.disinfectRoomList?.forEach(e=>{
              e.children?.forEach(c=>{                           
                if(c.clientId==client_id && c.isOpen){                   
                  state.disinfectIsOpen=c.isOpen;   
                }
              });
            });  

            state.powerRoomList?.forEach(e=>{
              e.children?.forEach(c=>{                           
                if(c.clientId==client_id && c.isOpen){                   
                  state.powerIsOpen=c.isOpen;   
                }
              });
            }); 

            state.acRoomList?.forEach(e=>{
              e.children?.forEach(c=>{                           
                if(c.clientId==client_id && c.isOpen){                   
                  state.acIsOpen=c.isOpen;   
                }
              });
            }); 


          });
       
      }
    },
    changeMenu(state,param) {
      state.leftMenu = param

      param.forEach(e=>{        
        state.lightNoAll=state.lightNoAll+e.lightNo;
        state.curtainNoAll=state.curtainNoAll+e.curtainNo;
        state.disinfectNoAll=state.disinfectNoAll+e.disinfectNo;
        state.powerNoAll=state.powerNoAll+e.powerNo;
        state.acNoAll=state.acNoAll+e.acNo;
      });
    },

    changeLightRoomList(state,param) {
      state.lightRoomList = param;      

      state.lightRoomList?.forEach(e=>{
        e.children?.forEach(c=>{            
          if(c.isOpen){  
            state.lightIsOpen=c.isOpen;                    
          }
        });
      });  
    },

    changeCurtainRoomList(state,param) {
      state.curtainRoomList = param;      

      state.curtainRoomList?.forEach(e=>{
        e.children?.forEach(c=>{            
          if(c.isOpen){  
            state.curtainIsOpen=c.isOpen;                    
          }
        });
      });  
    },
    
    changeACRoomList(state,param) {
      state.acRoomList = param;      
     
      state.acRoomList?.forEach(e=>{
        e.children?.forEach(c=>{            
          if(c.isOpen){  
            state.acIsOpen=c.isOpen;                    
          }
        });
      });  
    },
    changeDisinfectRoomList(state,param) {
      state.disinfectRoomList = param;   
      
      state.disinfectRoomList?.forEach(e=>{
        e.children?.forEach(c=>{                           
          if(c.isOpen){                   
            state.disinfectIsOpen=c.isOpen;   
          }
        });
      }); 
    },
    changePowerRoomList(state,param) {
      state.powerRoomList = param;   
      
      state.powerRoomList?.forEach(e=>{
        e.children?.forEach(c=>{                           
          if(c.isOpen){                   
            state.powerIsOpen=c.isOpen;   
          }
        });
      }); 
    },
    sceneStatus(state,msg){     
      state.lightRoomList?.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){                  
            c.isOpen=msg.device_id==1;   
            state.lightIsOpen=c.isOpen;          
          }
        });
      });
    },
    curtainStatus(state,msg){
      state.curtainRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){                  
            c.isOpen=msg.status;   
            state.curtainIsOpen=c.isOpen;         
          }
        });
      });
    },
    disinfectStatus(state,msg){
      state.disinfectRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){                  
            c.isOpen=msg.status;   
            state.disinfectIsOpen=c.isOpen;         
          }
        });
      });
    },
    powerStatus(state,msg){
      state.powerRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){                  
            c.isOpen=msg.status;   
            state.powerIsOpen=c.isOpen;         
          }
        });
      });
    },
    acStatus(state,msg){
      state.acRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){                  
            c.isOpen=msg.status;   
            state.acIsOpen=c.isOpen;         
          }
        });
      });
    },
    setLightOpen(state,open){
      state.lightIsOpen=open;      
    },    
    setBatLightOpen(state,param){  
      ///console.log(param);   
      state.lightRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          //console.log(param.clientIds.indexOf(c.clientId));
          if(param.clientIds.indexOf(c.clientId)>=0){                  
            c.isOpen=param.isOpen;  
          }
        });
      });     
    },
    setCurtainOpen(state,open){
      state.curtainIsOpen=open;      
    },
    setBatCurtainOpen(state,param){  
      ///console.log(param);   
      state.curtainRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          //console.log(param.clientIds.indexOf(c.clientId));
          if(param.clientIds.indexOf(c.clientId)>=0){                  
            c.isOpen=param.isOpen;  
          }
        });
      });     
    },
    setACOpen(state,open){
      state.acIsOpen=open;      
    },
    setBatACOpen(state,param){  
     // console.log(param);   
      state.acRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          //console.log(param.clientIds.indexOf(c.clientId));
          if(param.clientIds.indexOf(c.clientId)>=0){                  
            c.isOpen=param.isOpen;  
          }
        });
      });     
    },
    setDisinfectOpen(state,open){
      state.disinfectIsOpen=open;
    },
    setBatDisinfectOpen(state,param){     
      state.disinfectRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(param.clientIds.indexOf(c.clientId)>=0){                  
            c.isOpen=param.isOpen;  
          }
        });
      });      
    },
    setPowerOpen(state,open){
      state.powerIsOpen=open;
    },
    setBatPowerOpen(state,param){
      console.log(param);
      state.powerRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(param.clientIds.indexOf(c.clientId)>=0){                  
            c.isOpen=param.isOpen;  
          }
        });
      });
      console.log(state.powerRoomList);
    },
    connected(state,msg){      
      state.lightRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.clientId==msg.client_id){
            
          }
        });
      });
    },
    disconnect(state,msg){
      console.log(msg.device_type);
      console.log(msg.device_id);
    },    
  },
  actions: {
  },
  modules: {
  }
})
